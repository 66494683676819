class CookiesPopup extends Component {

    /** @type HTMLElement */
    form;

    /**
     * @param {HTMLElement} el
     */
    constructor(el) {
        super(el);
        setTimeout(() => el.classList.add('is-active'), 1000);
        this.fineControl = el.querySelector('[data-fine-control]');
        this.default = el.querySelector('[data-default]');
        el.querySelector('[data-open-fine]').addEventListener('click', e => this.toggleFineControl(e, true));
        el.querySelector('[data-close-fine]').addEventListener('click', e => this.toggleFineControl(e, false));
        el.querySelectorAll('[data-control]').forEach(el => el.addEventListener('click', e => this.cookiesSwitch(e)));
        this.toggles = el.querySelectorAll('input[type=checkbox]');
        this.form = el.querySelector('form');
        this.form.addEventListener('submit', e => this.onFormSubmitted(e));
    }

    /**
     * @param{{consent: number, result: bool}} response
     */
    onConsentStored(response) {
        EventBus.getInstance().dispatchEvent('hideGlobalSpinner');
        if (response.result) {
            this.$el.classList.remove('is-active');
            EventBus.getInstance().dispatchEvent('cookiesConsentStored', {consent: response.consent});
            window.cookieConsent = response.consent;
        }
    }

    onSubmitted() {
        EventBus.getInstance().dispatchEvent('showGlobalSpinner');
    }

    /**
     * @param {MouseEvent} e
     * @param {Boolean} show
     */
    toggleFineControl(e, show) {
        e.preventDefault();
        this.default.classList.toggle('u-hidden', show);
        this.fineControl.classList.toggle('u-hidden', !show);
    }

    /**
     * @param {MouseEvent} e
     */
    cookiesSwitch(e) {
        e.preventDefault();
        const toggle = Number.parseInt(e.currentTarget.dataset.toggle);
        this.toggles.forEach(el => el.checked = toggle === 1);
        this.onSubmitted()
        fetch(e.currentTarget.getAttribute('href') + '&redirect=0').catch(error => console.error('error:', error)).then(r => r.json()).then(r => this.onConsentStored(r));
    }

    onFormSubmitted(e) {
        const data = new FormData(this.form);
        const params = new URLSearchParams();
        data.forEach((e, i) => params.append(i, e.toString()));
        params.append('redirect', '0');
        const url = this.form.action + "?" + params.toString();
        this.onSubmitted();
        fetch(url).catch(error => console.error('error:', error)).then(r => r.json()).then(r => this.onConsentStored(r));
        e.preventDefault();
    }

}